<template>
    <div class="form-box">
        <el-form-item :label="$t('sales.order.labels.client_type')" prop="receiver.company">
            <el-select v-model="receiverCompany" class="fullWidth" filterable>
                <el-option :value="0" label="Persoana fizica"></el-option>
                <el-option :value="1" label="Persoana juridica"></el-option>
            </el-select>
        </el-form-item>

        <el-form-item v-if="receiverCompany" :label="$t('sales.order.labels.name')" prop="receiver.name">
            <el-input v-model="receiverName"></el-input>
        </el-form-item>

        <el-form-item :label="$t('sales.order.labels.contact')" prop="receiver.contact">
            <el-input v-model="receiverContact"></el-input>
        </el-form-item>

        <el-form-item :label="$t('sales.order.labels.phone')" prop="receiver.phone">
            <el-input v-model="receiverPhone"></el-input>
        </el-form-item>

        <template v-if="!enableLocker">
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item :label="$t('sales.order.labels.county')" prop="receiver.county">
                        <el-select v-model="receiverCounty" filterable class="fullWidth">
                            <el-option
                                v-for="county in counties"
                                :key="county"
                                :label="county"
                                :value="county"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('sales.order.labels.city')" prop="receiver.city">
                        <el-select v-model="receiverCity" filterable class="fullWidth">
                            <el-option
                                v-for="(city, index) in receiverCities"
                                :key="index"
                                :label="city"
                                :value="city"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item :label="$t('sales.order.labels.address')" prop="receiver.address">
                <el-input v-model="receiverAddress"></el-input>
            </el-form-item>
        </template>

        <template v-if="courierHasLockerActive && courierAccountHasLockerActive">
            <div class="enable-checkbox">
                <el-checkbox v-model="enableLocker">Livreaza produsele la locker</el-checkbox>
            </div>
            <Locker
                v-if="enableLocker"
                :lockerID="pickupPointID"
                @invalidPresetLocker="onInvalidPresetLocker"
                @lockerChanged="onLockerChanged"
            ></Locker>
        </template>
    </div>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
const Locker = () => import('./Locker.vue');

export default {
    components: {
        Locker,
    },
    props: {
        courierID: {
            type: Number,
            default: 0,
        },
        pickupPointID: {
            type: Number,
            default: 0,
        },
        preselectedCourierID: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        selectedLocker: null,
    }),
    computed: {
        couriers: sync('sales/order@awb.couriers'),
        counties: get('sales/order@awb.counties'),
        receiverCities: sync('sales/order@awb.receiverCities'),
        ...sync('sales/order@awb.form.receiver', {
            receiverCompany: 'company',
            receiverName: 'name',
            receiverContact: 'contact',
            receiverPhone: 'phone',
            receiverCounty: 'county',
            receiverCity: 'city',
            receiverAddress: 'address',
            lockerDeliveryId: 'locker_delivery_id',
            enableLocker: 'enable_locker',
        }),
        ...sync('sales/order@awb.form', {
            formCourierId: 'courier_id',
            courierAccountId: 'courier_account',
        }),
        currentCourier() {
            if (this.formCourierId) {
                return this.couriers[this.formCourierId];
            }
            return false;
        },
        courierHasLockerActive() {
            if (!this.currentCourier) {
                return false;
            }
            return this.currentCourier?.hasLockers == 1;
        },
        courierAccountHasLockerActive() {
            if (!this.currentCourier || !this.courierAccountId) {
                return false;
            }
            return this.currentCourier?.accounts?.[this.courierAccountId]?.enableLockers == 1;
        },
    },
    methods: {
        getCities: call('app/GetCitiesList'),
        getLockersRegions: call('sales/GetLockersRegions'),
        getCourierLockers: call('sales/GetCourierLockers'),
        changeCounty(clear) {
            this.receiverCities = [];

            if (clear) {
                this.receiverCity = '';
            }
            if (this.receiverCounty === '') {
                return;
            }

            this.getCities({
                county: this.receiverCounty,
            })
                .then((res) => {
                    this.receiverCities = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCities',
                            params: {
                                county: this.receiverCounty,
                            },
                        },
                    });
                });
        },
        onLockerChanged({ id, locker }) {
            if (id == this.pickupPointID && this.courierID != this.preselectedCourierID) {
                return this.resetLocker();
            }

            this.lockerDeliveryId = id;
            this.selectedLocker = locker;
            if (locker) {
                this.receiverCity = this.selectedLocker.town;
                this.receiverAddress = this.selectedLocker.address;
                this.receiverCounty = this.selectedLocker.region;
            } else {
                this.receiverCity = '';
                this.receiverAddress = '';
                this.receiverCounty = '';
            }
        },
        resetLocker() {
            this.lockerDeliveryId = 0;
            this.selectedLocker = null;
            this.receiverCity = '';
            this.receiverAddress = '';
            this.receiverCounty = '';
        },
        onInvalidPresetLocker() {
            // this.enableLocker = false;
        },
    },
    async mounted() {
        this.changeCounty();
        if (this.pickupPointID > 0 && this.courierID == this.preselectedCourierID) {
            this.lockerDeliveryId = this.pickupPointID;
            this.enableLocker = true;
        } else {
            this.enableLocker = false;
        }
    },
    watch: {
        receiverCounty(_, oldValue) {
            if (oldValue !== '') {
                this.changeCounty(true);
            } else {
                this.changeCounty();
            }
        },
        formCourierId() {
            if (this.pickupPointID > 0 && this.courierID == this.preselectedCourierID) {
                this.enableLocker = true;
            } else {
                this.enableLocker = false;
            }
        },
        enableLocker(val) {
            if (val) {
                return;
            }
            this.receiverCounty = '';
            this.receiverCity = '';
            this.receiverAddress = '';
            this.resetLocker();
        },
    },
};
</script>
